import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
    Button,
    MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { Alert } from '@material-ui/lab';
import LinearProgress from '@material-ui/core/LinearProgress';
import QRCode from 'qrcode.react';
import icon from '../assets/images/icon_image.png';

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#141D49',
    },
    iconStyle: {
        color: '#bcbaba',
    },
    heading: {
        fontSize: '36px',
        color: 'white',
        fontFamily: '',
    },
    formStyle: {
        display: 'flex',
        flexDirection: 'column',
        background: '#1F326B',
        borderRadius: '30px',
        marginTop: '8%',
        padding: '8% 6%',
    },
    dataList: {
        display: 'flex',
        flexDirection: 'column',
        background: '#2E4281',
        borderRadius: '30px',
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    Table: {
        color: 'white',
    },
    textField: {
        textAlign: 'left',
        color: 'rgb(188, 186, 186)',
        background: '#ffff',
        "& .MuiSelect-outlined": {
            color: 'rgb(188, 186, 186)',
        },

        margin: '5px'
    },
    input: {
        '&:-webkit-autofill': {
            WebkitTextFillColor: 'white !important',
            borderWidth: '1px !important',
            borderColor: '#282454 !important',
        },
    },
    result: {
        margin: '6px',
        color: '#6062A1',
        fontSize: '16px',
        fontWeight: 600,
    },
    res: {
        fontSize: '16px',
        color: '#141029'
    },
    buttonStyle: {
        color: '#D2D5E1',
        background: '#141029',
        borderRadius: '50px',
        padding: '8px 35px',
        marginTop: '35px',
        "&:hover": {
            color: "#000",
            border: '1px solid #141029'
        },
    },
    placeHolder: {
        color: 'rgb(188, 186, 186)',
        '&:hover': {
            color: '#1E3069'
        }
    }
}));

const cryptoList = ['BTC', 'ETH', 'USDT', 'BCH'];

const fiatList = ['USD', 'EUR', 'AUD', 'GBP', 'CAD', 'JPY'];

const initialForm = {
    orderNo: + new Date(),
    cryptoType: "",
    paymentMethod: "CRYPTO",
    amount: "",
    fiat: "",
    callbackUrl: "https://8b4f12760e56f7de7b144a55ec067dbb.m.pipedream.net"
}

const Crypto: React.FC = () => {
    const classes = useStyles();
    const [form, setForm] = React.useState(initialForm);
    const [apiRes, setApiRes] = React.useState<any>({});
    const [open, setOpen] = React.useState(false);
    const [displayError, setDisplayError] = React.useState(false);
    const [errors, setErrors] = React.useState<any>({});
    const [errorMsg, setErrorMsg] = React.useState<any>('');
    const [loading, setLoading] = React.useState(false)
    const [selectedLanguage, setSelectedLanguage] = React.useState('');

    const merchantSecret = "MbYHsB2XvzkuPhCN"
    const time = Math.round((new Date()).getTime() / 1000);
    const merchanApiKey = 'AAA333MM222';
    const prehash = "#" + time + "@" + merchantSecret + "#";
    const hash = CryptoJS.MD5(prehash).toString();

    const headers = {
        'api-key': `${merchanApiKey}`,
        'authorization': `${hash}`,
        'time': `${time}`,
    }
    const onSubmit = () => {
        setDisplayError(false)
        setLoading(true)
        setErrors({})
        setErrorMsg('')
        axios.post('https://stgapi.vanillafx.io/v1/crypto-payments', { ...form, orderNo: + new Date() }, { headers: headers })
            .then((res) => {
                setOpen(true);
                setApiRes(res);
                setLoading(false)
            })
            .catch((error) => {
                setDisplayError(true)
                setLoading(false)
                if (error) {

                    if (error?.response?.data?.errors) {
                        setErrors(error.response.data.errors);
                    }
                    else if (error?.response?.data?.errorMsg) {
                        setErrorMsg(error.response.data.errorMsg.message);
                    }
                }
            })
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setDisplayError(false);
    };

    useEffect(() => {
        /**
         * Load selected lang strings
         */
        const lang = localStorage.getItem('selectedLocale') || 'en';
        import(`./../locales/${lang}.json`)
            .then((res) => {
                intl.init({
                    currentLocale: lang,
                    locales: {
                        [lang]: res.default,
                    },
                });
                setSelectedLanguage(lang);
            });
    }, [selectedLanguage]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const lang = event.target.checked ? 'zh' : 'en';
        localStorage.setItem('selectedLocale', lang);
        setSelectedLanguage(lang);
        /**
         * Reload the app to load the i18n content in the whole app
         */
        window.location.reload();
        // window.location.search = `?lang=${lang}`;
    };
    return (
        <>
            <div>
                <div style={{ height: '98vh', background: '#141129' }}>
                    {loading ? <LinearProgress className={classes.root} /> : null}
                    <div className="lang-switch-container" style={{ display: 'flex', justifyContent: 'end', padding: '20px' }}>
                        <label className="switch" htmlFor="togBtn">
                            <input type="checkbox" id="togBtn" checked={selectedLanguage === 'zh'} onChange={handleChange} />
                            <div className="slider round" />
                        </label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '80px 0' }}>
                        <span><img src={icon} width="90" alt="demo" /></span>
                        <span className="heading">{intl.get('CRYPTO_SELL')}</span>
                    </div>
                    <div style={{ background: '#D0CFD4', borderTopRightRadius: '65px', borderTopLeftRadius: '65px', height: '72%', padding: '80px 0 8px 0' }}>
                        {apiRes && Object.keys(apiRes).length === 0 && apiRes.constructor === Object ?
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid item xs={10} sm={6}
                                        container
                                    >
                                        <label style={{ color: '#1E3069' }}>{intl.get('I_HAVE')}</label>
                                        <TextField style={{ width: '100%' }}
                                            id="outlined-select-currency"
                                            select
                                            label={intl.get('PLEASE_SELECT_YOUR_CRYPTO_CURRENCY')}
                                            value={form.cryptoType}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setForm(
                                                    { ...form, cryptoType: (event.target as HTMLInputElement).value },
                                                )
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                className: classes.placeHolder,
                                            }}
                                            className={classes.textField}
                                        >
                                            {cryptoList.map((value) => (
                                                <MenuItem key={value} value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid item xs={10} sm={6} container>
                                        <label style={{ color: '#1E3069' }}>{intl.get('I_WANT')}</label>
                                        <TextField style={{ width: '100%' }}
                                            id="outlined-select-currency"
                                            select
                                            label={intl.get('PLEASE_SELECT_YOUR_FIAT_CURRENCY')}
                                            value={form.fiat}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setForm(
                                                    { ...form, fiat: (event.target as HTMLInputElement).value },
                                                )
                                            }}

                                            variant="outlined"
                                            InputLabelProps={{
                                                className: classes.placeHolder,
                                            }}
                                            className={classes.textField}
                                        >
                                            {fiatList.map((value) => (
                                                <MenuItem key={value} value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid item xs={10} sm={4} container>
                                        <label style={{ color: '#1E3069' }}>{intl.get('AMOUNT')}</label>
                                        <TextField style={{ width: '100%' }}
                                            label={intl.get('PLEASE_ENTER_THE_AMOUNT')}
                                            value={form.amount}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setForm(
                                                    { ...form, amount: (event.target as HTMLInputElement).value },
                                                )
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                className: classes.placeHolder,
                                            }}
                                            className={classes.textField}
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={10} sm={2}>
                                        <Button onClick={() => onSubmit()} className={classes.buttonStyle}>{intl.get('SUBMIT')}</Button>
                                    </Grid>
                                </div>
                            </div>
                            :
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Grid item xs={10} sm={12} md={12} lg={12}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                <div className={classes.result}>
                                                    <span>{intl.get('PRICE')} : <b className={classes.res}>{apiRes.data.price}</b></span>
                                                </div>
                                                <div className={classes.result}>
                                                    <span>{intl.get('DEPOSIT_AMOUNT')} : <b className={classes.res}>{apiRes.data.depositAmount}</b></span>
                                                </div>
                                                <div className={classes.result} style={{ textAlign: 'left' }}>
                                                    <span>{intl.get('DEPOSIT_ADDRESS')} : <b className={classes.res}>{apiRes.data.depositAddress}</b></span>
                                                </div>
                                                <div className={classes.result}>
                                                    <span>{intl.get('CRYPTO_SELL')} : <b className={classes.res}>{apiRes.data.depositCrypto}</b></span>
                                                </div>
                                                <div className={classes.result}>
                                                    {/* {new Date(apiRes?.data?.expiryTime).toLocaleDateString()} {new Date(apiRes.data.expiryTime).toLocaleTimeString()} */}
                                                    <span>{intl.get('EXPIRY_TIME')} : <b className={classes.res}>{new Date(apiRes?.data?.expiryTime).toLocaleDateString()} {new Date(apiRes.data.expiryTime).toLocaleTimeString()}</b></span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 10px' }}>
                                        <Grid item xs={10} sm={12} md={5} lg={4}>
                                            <span>
                                                <QRCode style={{ padding: '20px', background: '#fff', margin: '20px 10px' }} value={apiRes.data.depositAddress} size={200} />
                                            </span>
                                        </Grid>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid item xs={10} sm={2}>
                                        <Button onClick={() => { setApiRes({}); setForm(initialForm); }} className={classes.buttonStyle}>{intl.get('BACK')}</Button>
                                    </Grid>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                {intl.get('SUCCESS')}!
                </Alert>
            </Snackbar>
            {Object.values(errors).length ?
                Object.values(errors).map(
                    (error: any) => <Snackbar open={displayError} autoHideDuration={8000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                ) : null}
            {
                errorMsg ? <Snackbar open={displayError} autoHideDuration={8000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {errorMsg}
                    </Alert>
                </Snackbar> : null
            }
        </>
    );
};

export default Crypto